body {font-weight: 300; font-size: 0.9rem;}

#wrapper {
	min-height: 100%;
	height: 100vh;
	.page-slider, .page-content {
		min-height: 100%;
		height: 100vh;
	}
}

.home {
	.content {
		height: 100% !important;
		.main {
			height: 100% !important;
			@include media-breakpoint-up(md) {overflow: auto;}
		}
	}
	.main {padding-left: 0; padding-right: 0;}
}

.home-logo{
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	margin: 50px 0 0 50px;
}

.bg-image {
	background-repeat: no-repeat; background-position: center center; background-size: cover;
}
.navigation {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transition: left 0.5s;
	background-color: rgba(0,0,0,0.9);
	color: #fff;
	@include media-breakpoint-up(sm) {left: calc(100% - 500px); width: 500px;}
	ul {
		padding-left: 0;
		margin-top: 50px;
		li {
			list-style: none;
			text-decoration-style: none;
			line-height: 170%;
			padding: 5px 0 5px 40px;
			a {
				text-decoration: none;
				text-transform: uppercase;
				font-size: 16px;
				color: #292929;
				&:hover {
					background-color: #56c2f0;
					cursor: pointer;
				}
			}
		}
	}
	&.open .toggle-wrapper .show {
		left: -50px;
	}
	&:not(.open) {
		left: 100%;
	}
	&:not(.open) .toggle-wrapper .show {
		left: 0px;
	}
	&:not(.open) .toggle-wrapper .hide {
		left: 50px;
	}
	.toggle-wrapper {
		height: 50px;
		width: 50px;
		color: white;
		overflow: hidden;
		position: absolute;
		top: 50px;
		left: -65px;
		@include media-breakpoint-up(sm) {top: 15px;}
		span {
			width: 50px;
			height: 50px;
			background-color: transparent;
			color: inherit;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			transition: left 0.25s;
		}
	}
	&.open .toggle-wrapper {
		left: auto;
		right: 15px;
	}
	&.open {
		@include media-breakpoint-down(sm) {overflow-y: scroll;}
	}
}

.sidemenu {
	.nav-tabs {
		.nav-link {
			color: #0e72b8;
			background-color: transparent;
			border-color: transparent;
			font-size: 20px;
			font-weight: 400;
			letter-spacing: .2rem;
			@include media-breakpoint-up(md) {font-size: 22px; letter-spacing: .3rem;}
			@include media-breakpoint-up(lg) {font-size: 24px; letter-spacing: .4rem;}
			&:hover {
				background-color: transparent;
				border-color: transparent;
			}
		}
	}
}

.smedia {
	ul {
		li {
			a:hover {background-color: transparent;}
		}
	}
}

.copyright {
	font-size: .85rem;
	@include media-breakpoint-down(sm) {position: absolute; bottom: 30px; font-size: .75rem;}
}